<template>
  <div class="table-container">
    <h1>Lagerverwaltung</h1>
    <div v-if="loading">
      <p>Loading...</p>
    </div>
    <div v-else>
      <table border="1">
        <thead>
          <tr>
            <th>Id</th>
            <th>Automat</th>
            <th>Produkt</th>
            <th>Menge</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.Id">
            <td>{{ product.Id || 'No Id' }}</td>
            <td>{{ product.Kategorie || 'No Kategorie' }}</td>
            <td>{{ product.Produkt || 'No Produkt' }}</td>
            <td>
              <input type="number" v-model.number="product.Menge" />
            </td>
            <td>
              <button @click="updateMenge(product)">Speichern</button>
              <button @click="deleteProduct(product.Id)" class="delete-button">Löschen</button>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <input v-model="newProduct.Kategorie" placeholder="Automat" />
            </td>
            <td>
              <input v-model="newProduct.Produkt" placeholder="Produktname" />
            </td>
            <td>
              <input type="number" v-model.number="newProduct.Menge" placeholder="Menge" />
            </td>
            <td>
              <button @click="addProduct">Hinzufügen</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      products: [],
      newProduct: {
        Produkt: '',
        Menge: 0,
        Kategorie: ''
      },
      loading: true // Neuer State für das Laden
    };
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get('https://www.tropicool.at/api/products');
        this.products = response.data;
      } catch (error) {
        console.error('Fehler beim Abrufen der Produkte:', error);
      } finally {
        this.loading = false; // Ladezustand beenden
      }
    },
    async updateMenge(product) {
      try {
        await axios.put(`https://www.tropicool.at/api/products/${encodeURIComponent(product.Produkt)}`, {
          menge: product.Menge
        });
        alert('Menge erfolgreich aktualisiert');
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Menge:', error);
      }
    },
    async addProduct() {
      if (this.newProduct.Produkt && this.newProduct.Kategorie) {
        try {
          await axios.post('https://www.tropicool.at/api/products', this.newProduct);
          this.fetchProducts();
          this.newProduct = { Produkt: '', Menge: 0, Kategorie: '' };
          alert('Produkt erfolgreich hinzugefügt');
        } catch (error) {
          console.error('Fehler beim Hinzufügen des Produkts:', error);
        }
      } else {
        alert('Bitte füllen Sie alle Felder aus.');
      }
    },
    async deleteProduct(productId) {
      if (confirm('Möchten Sie dieses Produkt wirklich löschen?')) {
        try {
          await axios.delete(`https://www.tropicool.at/api/products/${productId}`);
          this.fetchProducts();
          alert('Produkt erfolgreich gelöscht');
        } catch (error) {
          console.error('Fehler beim Löschen des Produkts:', error);
        }
      }
    }
  }
};
</script>

<style scoped>
/* Container styles */
.container {
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

/* Responsive table container */
.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

input[type="number"] {
  width: 100%;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  margin-right: 5px;
}

button:hover {
  background-color: #45a049;
}

.delete-button {
  background-color: #f44336;
}

.delete-button:hover {
  background-color: #e53935;
}

/* Styles for smaller screens */
@media screen and (max-width: 768px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  th {
    display: none; /* Hide table headers */
  }

  td {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  td:before {
    content: attr(data-label);
    font-weight: bold;
    width: 100px;
  }

  /* Form styles */
  .form-container {
    display: flex;
    flex-direction: column;
  }

  .form-container input {
    margin-bottom: 10px;
  }
}
</style>
